import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "filterButton",
    "moreButton",
    "tepmplateContainer"
  ];

  connect() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadTemplates();
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(this.element);
    this.hideButtons();
    this.initializeFilter();
    this.setActiveFilter("pdf");
    this.filterTemplates("pdf");
   }

  hideButtons() {
    const buttons = this.filterButtonTargets;
    const initialVisibleCount = 8;

    buttons.forEach((button, index) => {
      if (index >= initialVisibleCount) {
        button.classList.add("d-md-none");
        // button.classList.remove("d-inline-flex");
      } else {
        button.classList.remove("d-md-none");
        button.classList.add("d-inline-flex");
      }
    });
  }

  showAllButtons() {
    const buttons = this.filterButtonTargets;
    const moreButton = this.moreButtonTarget;
    const initialVisibleCount = 8;
    const showingAll = !buttons[initialVisibleCount]?.classList.contains("d-md-none");

    if (showingAll) {
      this.hideButtons();
      moreButton.classList.remove("rotated");
    } else {
      buttons.forEach(button => {
        button.classList.remove("d-md-none");
        button.classList.add("d-inline-flex");
      });
      moreButton.classList.add("rotated");
    }
  }

  initializeFilter() {
    const filterButtons = this.filterButtonTargets;

    filterButtons.forEach(button => {
      button.addEventListener('click', () => {
        const title = button.getAttribute('data-initial-value').toLowerCase();
        this.setActiveFilter(title);
        this.filterTemplates(title);
      });
    });
  }

  setActiveFilter(filterValue) {
    this.activeFilter = filterValue;
    const filterButtons = this.filterButtonTargets;

    filterButtons.forEach(button => {
      if (button.getAttribute('data-initial-value').toLowerCase() === filterValue) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    });
  }

  filterTemplates(title) {
    const templateItems = document.querySelectorAll('.template-main-item');
    const maxVisibleItems = 6;
    let visibleCount = 0;

    templateItems.forEach(item => {
      const itemTitle = item.getAttribute('data-initial-value');
      if (visibleCount < maxVisibleItems && (title === 'all' || title === itemTitle.toLowerCase())) {
        item.classList.remove("d-none");
        visibleCount++;
      } else {
        item.classList.add("d-none");
      }
    });
  }
  loadTemplates() {

    const templatesData = JSON.parse(this.tepmplateContainerTarget.dataset.templates);


    const templateHTML = templatesData.map(template => {
      return template.pagesSortedByPositionMeQr
        .map(
          (page) => `
        <div class="col-lg-2 col-md-4 col-8 mx-3 template-main-item overflow-hidden text-center" data-initial-value="${template.title}">
          <a href="/entry/constructor/clone/${page.id}">
            <img src="${page.image}" alt="Template Image ${page.id}" class="img-fluid template-main-item-img">
          </a>
        </div>
      `
        )
        .join("");
    }).join('');


    this.tepmplateContainerTarget.innerHTML = `
      <div class="d-flex flex-nowrap justify-content-xl-center justify-content-between mt-4 pb-4 template-main-container">
        ${templateHTML}
      </div>
    `;
    this.filterTemplates(this.activeFilter);
  }
}
