import { Controller } from "@hotwired/stimulus";
import axios from "axios";
export default class extends Controller {
  static targets = [
    "closeModal",
    "input",
    "inputEmptyErr",
    "inputDublicateErr",
    "inputWrongErr",
    "inputInternalErr",
  ];

  init(e) {
    let locale = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");
    if (locale === undefined) {
      locale = "en";
    }
    document.querySelector("#uniqueUrlInput").value = e.detail.link;
    document.querySelector("#uniqueUrlUpdate").dataset.href =
      e.detail.updateUrl;
  }

  async update(e) {
    const urlPattern = /^[0-9a-zA-Z\-\/]+$/;

    if (!this.inputTarget.value.length) {
      this.showErrorMsg(this.inputEmptyErrTarget);
      return;
    }

    if (!urlPattern.test(this.inputTarget.value)) {
      this.showErrorMsg(this.inputWrongErrTarget);
      return;
    }

    const formData = new FormData();
    formData.append("unique_key", this.inputTarget.value);
    try {
      await axios.post(e.currentTarget.dataset.href, formData);
      this.closeModalTarget.click();
      window.location.reload();
    } catch (e) {
      const response = e.response;
      const statusCode = response.status;
      if (statusCode === 400) {
        this.showErrorMsg(this.inputDublicateErrTarget);
      } else {
        this.showErrorMsg(this.inputInternalErrTarget);
      }
    }
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }
}
